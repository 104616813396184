.fade-enter {
    opacity: 0;
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  .fade-exit {
    opacity: 1;
  }
  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
  }
  
  /* Make sure your pages are responsive */
  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .error-message {
    color: red;
    text-align: center;
    margin-top: 20px;
  }
  /* Add this CSS to ensure the ad is responsive */
@media (max-width: 768px) {
  .adsbygoogle {
    width: 100% !important;  /* Ensures the ad takes up the full width on smaller screens */
    height: auto !important; /* Adjust height as needed */
  }
}
