/* Loading Spinner CSS */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full screen height */
    background-color: #f9f9f9; /* Light background */
  }
  
  .loading-container img {
    width: 40px; /* Adjust the size of the spinner */
    height: 40px;
    animation: spin 1.5s linear infinite; /* Animation for spinning */
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  